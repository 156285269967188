<template>
  <div class="row">
    <div class="col-12">
        <div class="product-card height-transition" v-bind:style="{ 'height': heightPic + 'px' }">
          <img v-if="!primaryImage" src="@/assets/images/product-photo-coming-soon.jpg" ref="noPic" class="product-card-image" alt="" @load="imgLoaded">
          <div v-else>
            <transition name="fade" mode="out-in">
              <img :src="primaryUrl" class="product-card-image fade-enter-active" :class="{'fade-enter': !showThumbs}" alt="" v-bind:key="selectedImage" ref="bigPic" @load="imgLoaded">
            </transition>
          </div>
        </div>
        <div class="row" v-if="images && images.length > 0" v-show="showThumbs">
          <div class="col-3" v-on:click="changePic(-1)">
            <img :src="imageUrl" class="img-width prod-thumb-border" alt="">
          </div>
          <div v-for="(img, index) in images" class="col-3" v-bind:key="img.id" v-on:click="changePic(index)">
            <img :src="gallUrl(img, false)" class="img-width prod-thumb-border" alt="">
          </div>
        </div>
    </div>
  </div>
</template>>


<script>
export default {
  name: "ProductPictureGallery",
  props: [
    "primaryImage",
    "images",
  ],
  data(){
    return {
      selectedImage: -1,
      heightPic: null,
      showThumbs: false,
    }
  },
  mounted: function () {
    if(this.$refs.bigPic && this.$refs.bigPic.clientHeight){
      this.heightPic = this.$refs.bigPic.clientHeight + 15;
    }
  },
  watch: {
    selectedImage: {
      immediate: true,
      handler: function () {
        const vm = this;
        setTimeout(() => {
          if(vm.$refs.bigPic && vm.$refs.bigPic.clientHeight){
            vm.heightPic = vm.$refs.bigPic.clientHeight + 15;
          }
          setTimeout(() => {
            // backup for slow connections
            if(vm.$refs.bigPic && vm.heightPic != vm.$refs.bigPic.clientHeight + 15){
              vm.heightPic = vm.$refs.bigPic.clientHeight + 15;
            }
          }, 1000);
        }, 350);
        }
    }
  },
  computed: {
    imageUrl () {
      const path = this.primaryImage ? this.primaryImage : null;
      if(path && path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
    primaryUrl(){
      if(this.selectedImage == -1){
        return this.imageUrl
      }
      const img = this.images[this.selectedImage];
      let path = img.thumb_big ? img.thumb_big : img.primary;
      if(path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
  },
  methods: {
    imgLoaded(){
      if(!this.primaryImage){
        if(this.$refs.noPic && this.$refs.noPic.clientHeight){
          this.heightPic = this.$refs.noPic.clientHeight + 15;
        }
      }
      if(!this.showThumbs){
        if(this.$refs.bigPic && this.$refs.bigPic.clientHeight){
          this.heightPic = this.$refs.bigPic.clientHeight + 15;
        }
        const vm = this;
        setTimeout(() => {
          vm.showThumbs = true;
        }, 100);
      }
    },
    changePic(index){
      this.selectedImage = index;
    },
    gallUrl(img, fullSize){
      let path = img.thumb_big && !fullSize ? img.thumb_big : img.primary;
      if(path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
  }
}
</script>