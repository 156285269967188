<template>
  <div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="animated-container">
      <SelectProductModal v-if="showMoreInfo"
        v-bind:product=moreInfoProduct
        v-bind:inCart=moreInfoProdInCart
        v-bind:currency=currency
        v-bind:requirePayment=requirePayment
        v-bind:headerStyles=headerStyles
        v-bind:containerStyles=containerStyles
        v-bind:buttonStyles=buttonStyles
        v-bind:limitType=limitType
        v-bind:isSelected=modalProductIsSelected
        v-bind:productSetIdx=modalProductSetIdx
        @closeMoreInfoModal="closeMoreInfoModal"
        @addToOrder="addToOrder"
        @updateOrder="updateOrder"
        @removeFromOrder="removeFromOrder"
      />
      <div v-if="aProducts && aProducts.length > 0">
        <div class="row">
          <div class="col-12 mt-4">
            <h1 :style="headerStyles" v-if="limitType === 1">{{ $t('orderHeaderLimited', {allowed: aProductsCount, pCount: aProducts.length}) }}</h1>
            <h1 :style="headerStyles" v-if="limitType === 2">{{ $t('orderHeaderBudget', {budget: orderBudget, currency: currency}) }}</h1>
            <h1 :style="headerStyles" v-else-if="limitType === 3">{{ $t('orderHeader') }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 mt-md-5 d-flex align-items-end" v-for="product in aProducts" v-bind:key="product.id">
            <div class="d-block" :class="maxSelected && !productIdsInOrder.includes(product.id) ? 'opacity-05' : ''">
              <img v-if="product.primary_thumb_big" :src="getImageUrl(product.primary_thumb_big)" class="w-100 pointer" v-on:click="showModal(product.id)">
              <img v-else src="@/assets/images/product-photo-coming-soon.jpg" class="w-100 pointer" alt="" v-on:click="showModal(product.id)">
              <div style="min-height: 200px">
                <div class="font-weight-bold mt-2">{{product.name}}</div>
                <div>
                  <div v-if="limitType === 3 && productIdsInOrder.includes(product.id)" style="overflow:hidden;">
                    <span v-for="p in legacyProductsData.filter(p => p.productId == product.id)" class="tag-grey" v-bind:key="p.productId + '_' + p.variantId">{{p.name}} x {{p.quantity}}</span>
                  </div>
                  <div v-else class="font-weight-bold grey-pointer" v-on:click="showModal(product.id)">{{ $t('orderViewInfo') }}</div>
                </div>
                <div class="font-weight-bold font-grey" style="clear: both;" v-if="requirePayment || limitType === 2">{{product.price}} {{currency}}</div>
                <div>
                  <button v-if="productIdsInOrder.includes(product.id)" class="btn btn-lg btn-block mt-2 button-hover" :style="buttonStyles.primary" v-on:click="showModal(product.id)">{{ $t('orderRemoveOrEdit') }}</button>
                  <button v-else :disabled="maxSelected" class="btn btn-lg btn-block mt-2 button-hover" :style="buttonStyles.secondary" v-on:click="showModal(product.id)">{{ $t('OrderAdd') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(ps, idx) in filteredProductSets" :key="idx">
        <div class="row">
          <div class="col-12 mt-4">
            <h1 :style="headerStyles" v-if="limitType === 1 && aProducts.length === 0 && idx === 0">{{ $t('orderHeaderLimited', {allowed: ps.max_order_count, pCount: ps.products.length}) }}</h1>
            <h1 :style="headerStyles" v-else-if="limitType === 1">{{ $t('orderHeaderLimitedNext', {allowed: ps.max_order_count, pCount: ps.products.length}) }}</h1>
            <h1 :style="headerStyles" v-else-if="limitType === 2">{{ $t('orderHeaderBudget', {budget: orderBudget, currency: currency}) }}</h1>
            <h1 :style="headerStyles" v-else-if="limitType === 3">{{ $t('orderHeader') }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 mt-md-5 d-flex align-items-end" v-for="product in ps.products" v-bind:key="product.id">
            <div class="d-block" :class="maxSelectedInSet(idx) && !productSelectedInSet(product.id, idx) ? 'opacity-05' : ''">
              <img v-if="product.primary_thumb_big" :src="getImageUrl(product.primary_thumb_big)" class="w-100 pointer" v-on:click="showModal(product.id, idx)">
              <img v-else src="@/assets/images/product-photo-coming-soon.jpg" class="w-100 pointer" alt="" v-on:click="showModal(product.id, idx)">
              <div style="min-height: 200px">
                <div class="font-weight-bold mt-2">{{product.name}}</div>
                <div>
                  <div v-if="limitType === 3 && productIdsInOrder.includes(product.id)" style="overflow:hidden;">
                    <span v-for="p in legacyProductsData.filter(p => p.productId == product.id)" class="tag-grey" v-bind:key="p.productId + '_' + p.variantId">{{p.name}} x {{p.quantity}}</span>
                  </div>
                  <div v-else class="font-weight-bold grey-pointer" v-on:click="showModal(product.id, idx)">{{ $t('orderViewInfo') }}</div>
                </div>
                <div class="font-weight-bold font-grey" style="clear: both;" v-if="requirePayment || limitType === 2">{{product.price}} {{currency}}</div>
                <div>
                  <button v-if="productSelectedInSet(product.id, idx)" class="btn btn-lg  btn-block mt-2 button-hover" :style="buttonStyles.primary" v-on:click="showModal(product.id, idx)">{{ $t('orderRemoveOrEdit') }}</button>
                  <button v-else :disabled="maxSelectedInSet(idx)" class="btn btn-lg btn-block mt-2 button-hover" :style="buttonStyles.secondary" v-on:click="showModal(product.id, idx)">{{ $t('OrderAdd') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <hr />
          </div>
        </div>
      </div>
      <div v-if="questionIncluded">
        <div class="row mt-2">
          <div class="col-12">
            <h1 :style="headerStyles">{{ $t('orderQuickQuestion') }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3">
            <div>{{questionText}}</div>
          </div>
          <div class="col-12 col-md-3">
            <div>{{ $t('orderQuickQuestionAnswer') }}<span v-if="questionMandatory">*</span></div>
            <textarea :style="containerStyles" v-model="orderData.answer" rows=5 />
          </div>
          <div v-if="allowAttachment" class="col-12 col-md-3">
            <div>{{ $t('orderQuickQuestionAttachment') }}</div>
            <label for="attachment" class="custom-file-upload-question mt-0 mb-0" :style="buttonStyles.primary">
              <i class="fa fa-cloud-upload"></i> {{ $t('orderQuickQuestionAttachmentButton') }}
            </label>
            <input class="inputfile" type="file" id="attachment" ref="attachment" v-on:change="handleFileUpload()"/>
            <div class="text-break" v-if="attachment && attachment.name">{{attachment.name}}<span class="remove-x float-right mt-1 pointer" @click="removeAttachment">X</span></div>
          </div>
          <div class="col-12 col-md-3">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <hr />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h1 :style="headerStyles">{{ $t('orderDeliveryDetailsHeader') }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3">
          <div>{{ $t('orderDeliveryDetailsFname', {requiredSymbol: '*'}) }}</div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.first_name">
          <div v-if="$v.orderData.first_name.$error">
            <div class="form-field-error" v-if="!$v.orderData.first_name.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-if="!$v.orderData.first_name.latinChars">{{ $t('latinCharsValidate') }}</div>
          </div>
        </div>
        <div class="col-12 col-md-3 mt-4 mt-md-0">
          <div>{{ $t('orderDeliveryDetailsLname', {requiredSymbol: '*'}) }}</div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.last_name">
          <div v-if="$v.orderData.last_name.$error">
            <div class="form-field-error" v-if="!$v.orderData.last_name.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-if="!$v.orderData.last_name.latinChars">{{ $t('latinCharsValidate') }}</div>
          </div>
        </div>
        <div class="col-12 col-md-3 mt-4 mt-md-0">
          <div>{{ $t('orderDeliveryDetailsEmail') }}* <img id="email-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
            <b-tooltip target="email-tooltip" triggers="hover">
              {{ $t('orderDeliveryDetailsEmailTooltip') }}
            </b-tooltip>
          </div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.email">
          <div v-if="$v.orderData.email.$error">
            <div class="form-field-error" v-if="!$v.orderData.email.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-else-if="!$v.orderData.email.email">{{ $t('notValidEmail') }}</div>
            <div class="form-field-error" v-else-if="!$v.orderData.email.latinChars">{{ $t('latinCharsValidate') }}</div>
          </div>
        </div>
        <div class="col-12 col-md-3 mt-4 mt-md-0">
          <div>{{ $t('orderDeliveryDetailsPhone') }}* <img id="phone-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
            <b-tooltip target="phone-tooltip" triggers="hover">
             {{ $t('orderDeliveryDetailsPhoneTooltip') }}
            </b-tooltip>
          </div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.phone">
          <div v-if="$v.orderData.phone.$error">
            <div class="form-field-error" v-if="!$v.orderData.phone.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-if="!$v.orderData.phone.phoneNumber">{{ $t('notValidPhone') }}</div>
          </div>
        </div>
      </div>
      <div v-if="limit_addresses === true || limit_addresses === null">
        <div class="row mt-md-4">
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryTo', {requiredSymbol: '*'}) }}</div>
            <v-select
              label="name"
              v-model="orderData.address"
              :options="adjustedAddresses"
              :reduce="a => a.id"
              :clearable="false"
              placeholder="Select address"
              class="mySelect"
              :style="{ '--dropdown-bg-color': dropdownBackgroundColor.backgroundColor, '--dropdown-color': dropdownBackgroundColor.color,
               '--dropdown-highlight-bg': dropdownHighlight.backgroundColor, '--dropdown-highlight-color': dropdownHighlight.color }"
            ></v-select>
            <div v-if="$v.orderData.address.$error">
              <div class="form-field-error" v-if="!$v.orderData.address.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            </div>
          </div>
        </div>
        <div v-if="limit_addresses === null && (!orderData.address || orderData.address === 'new')" class="row mt-md-4">
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryDetailsStreet', {requiredSymbol: '*'}) }}</div>
            <input type="text" :style="containerStyles" class="inputfield inputfield-bg" :disabled="!orderData.address" v-model="orderData.street">
            <div v-if="$v.orderData.street.$error">
              <div class="form-field-error" v-if="!$v.orderData.street.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
              <div class="form-field-error" v-if="!$v.orderData.street.latinChars">{{ $t('latinCharsValidate') }}</div>
              <div class="form-field-error" v-if="!$v.orderData.street.maxLength">{{ $t('maxLengthValidate', {maxLength: $v.orderData.street.$params.maxLength.max}) }}</div>
            </div>
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryDetailsPost') }}*</div>
            <input type="text" :style="containerStyles" class="inputfield inputfield-bg" :disabled="!orderData.address" v-model="orderData.zip">
            <div v-if="$v.orderData.zip.$error">
              <div class="form-field-error" v-if="!$v.orderData.zip.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
              <div class="form-field-error" v-else-if="!$v.orderData.zip.maxLength">{{ $t('maxLengthValidate', {maxLength: $v.orderData.zip.$params.maxLength.max}) }}</div>
              <div class="form-field-error" v-else-if="!$v.orderData.zip.latinChars">{{ $t('latinCharsValidate') }}</div>
            </div>
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryDetailsCity', {requiredSymbol: '*'}) }}</div>
            <input type="text" :style="containerStyles" class="inputfield inputfield-bg" :disabled="!orderData.address" v-model="orderData.city">
            <div v-if="$v.orderData.city.$error">
              <div class="form-field-error" v-if="!$v.orderData.city.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
              <div class="form-field-error" v-if="!$v.orderData.city.latinChars">{{ $t('latinCharsValidate') }}</div>
            </div>
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryDetailsCountry') }}*</div>
            <v-select
              label="name"
              v-model="orderData.country"
              :options="countries"
              :reduce="s => s.value"
              :clearable="false"
              :placeholder="$t('orderSelectCountry')"
              :disabled="!orderData.address"
              class="mySelect"
              :style="{ '--dropdown-bg-color': dropdownBackgroundColor.backgroundColor, '--dropdown-color': dropdownBackgroundColor.color,
               '--dropdown-highlight-bg': dropdownHighlight.backgroundColor, '--dropdown-highlight-color': dropdownHighlight.color }"
            ></v-select>
            <div v-if="$v.orderData.country.$error">
              <div class="form-field-error" v-if="!$v.orderData.country.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
              <div class="form-field-error" v-if="!$v.orderData.country.latinChars">{{ $t('latinCharsValidate') }}</div>
            </div>
          </div>
        </div>
        <div v-else class="row mt-md-4">
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryDetailsStreet', {requiredSymbol: '*'}) }}</div>
            <input type="text" :style="containerStyles" class="inputfield inputfield-bg" disabled :value="streetAddress">
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryDetailsPost') }}*</div>
            <input type="text" :style="containerStyles" class="inputfield inputfield-bg" disabled :value="selectedAddress.zip_code">
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryDetailsCity', {requiredSymbol: '*'}) }}</div>
            <input type="text" :style="containerStyles" class="inputfield inputfield-bg" disabled :value="selectedAddress.city">
          </div>
          <div class="col-12 col-md-3 mt-4 mt-md-0">
            <div>{{ $t('orderDeliveryDetailsCountry') }}*</div>
            <input type="text" :style="containerStyles" class="inputfield inputfield-bg" disabled :value="selectedAddress.country">
          </div>
        </div>
      </div>
      <div v-else class="row mt-md-4">
        <div class="col-12 col-md-3 mt-4 mt-md-0">
          <div>{{ $t('orderDeliveryDetailsStreet', {requiredSymbol: '*'}) }}</div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.street">
          <div v-if="$v.orderData.street.$error">
            <div class="form-field-error" v-if="!$v.orderData.street.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-if="!$v.orderData.street.latinChars">{{ $t('latinCharsValidate') }}</div>
            <div class="form-field-error" v-if="!$v.orderData.street.maxLength">{{ $t('maxLengthValidate', {maxLength: $v.orderData.street.$params.maxLength.max}) }}</div>
          </div>
        </div>
        <div class="col-12 col-md-3 mt-4 mt-md-0">
          <div>{{ $t('orderDeliveryDetailsCity', {requiredSymbol: '*'}) }}</div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.city">
          <div v-if="$v.orderData.city.$error">
            <div class="form-field-error" v-if="!$v.orderData.city.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-if="!$v.orderData.city.latinChars">{{ $t('latinCharsValidate') }}</div>
          </div>
        </div>
        <div class="col-12 col-md-3 mt-4 mt-md-0">
          <div>{{ $t('orderDeliveryDetailsPost') }}*</div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.zip">
          <div v-if="$v.orderData.zip.$error">
            <div class="form-field-error" v-if="!$v.orderData.zip.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-else-if="!$v.orderData.zip.maxLength">{{ $t('maxLengthValidate', {maxLength: $v.orderData.zip.$params.maxLength.max}) }}</div>
            <div class="form-field-error" v-else-if="!$v.orderData.zip.latinChars">{{ $t('latinCharsValidate') }}</div>
          </div>
        </div>
        <div class="col-12 col-md-3 mt-4 mt-md-0">
          <div>{{ $t('orderDeliveryDetailsCountry') }}*</div>
          <v-select
              label="name"
              v-model="orderData.country"
              :options="countries"
              :reduce="s => s.value"
              :clearable="false"
              :placeholder="$t('orderSelectCountry')"
              class="mySelect"
              :style="{ '--dropdown-bg-color': dropdownBackgroundColor.backgroundColor, '--dropdown-color': dropdownBackgroundColor.color,
               '--dropdown-highlight-bg': dropdownHighlight.backgroundColor, '--dropdown-highlight-color': dropdownHighlight.color }"
          ></v-select>
          <div v-if="$v.orderData.country.$error">
            <div class="form-field-error" v-if="!$v.orderData.country.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-if="!$v.orderData.country.latinChars">{{ $t('latinCharsValidate') }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-md-3">
          <div v-if="requirePayment && allowInvoice">
            <div>{{ $t('choosePaymentMethod') }}</div>
            <v-select
                label="name"
                v-model="orderData.payment_method"
                :options="[{name: $t('methodCreditCard'), value: 1}, {name: $t('methodInvoice'), value: 2}]"
                :reduce="s => s.value"
                :clearable="false"
                class="mySelect"
                :style="{ '--dropdown-bg-color': dropdownBackgroundColor.backgroundColor, '--dropdown-color': dropdownBackgroundColor.color,
                '--dropdown-highlight-bg': dropdownHighlight.backgroundColor, '--dropdown-highlight-color': dropdownHighlight.color }"
            ></v-select>
          </div>
        </div>
        <div v-if="orderData.payment_method === 2" class="col-12 col-md-3">
          <div>{{ $t('orderDeliveryDetailsCompanyName') }}*</div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.company_name">
          <div v-if="$v.orderData.company_name.$error">
            <div class="form-field-error" v-if="!$v.orderData.company_name.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
          </div>
        </div>
        <div v-if="orderData.payment_method === 2" class="col-12 col-md-3">
          <div>{{ $t('orderDeliveryDetailsCompanyVatNumber') }}*</div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.company_vat_number">
          <div v-if="$v.orderData.company_vat_number.$error">
            <div class="form-field-error" v-if="!$v.orderData.company_vat_number.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
          </div>
        </div>
        <div v-if="orderData.payment_method === 2" class="col-12 col-md-3">
          <div>{{ $t('orderDeliveryDetailsCompanyInvoiceEmail') }}*</div>
          <input type="text" :style="containerStyles" class="inputfield inputfield-bg" v-model="orderData.company_invoice_email">
          <div v-if="$v.orderData.company_invoice_email.$error">
            <div class="form-field-error" v-if="!$v.orderData.company_invoice_email.required">{{ $t('orderDeliveryDetailsValidate') }}</div>
            <div class="form-field-error" v-else-if="!$v.orderData.company_invoice_email.email">{{ $t('notValidEmail') }}</div>
          </div>
        </div>
        <div v-if="orderData.payment_method === 1" class="col-12 col-md-6 pt-3">
          <div v-if="requirePayment">
            <div class="font-weight-bold">{{ $t('summary') }}</div>
            <div class="d-flex justify-content-between">
              <div>{{ $t('products') }}</div>
              <div>{{ subtotalPrice.toFixed(2) }} {{ currency }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div>{{ $t('delivery') }}</div>
              <div>{{ adjustedDeliveryFee.toFixed(2) }} {{ currency }}</div>
            </div>
            <div class="d-flex justify-content-between font-weight-bold mb-3">
              <div>{{ $t('orderTotal') }}</div>
              <div>{{ totalPrice.toFixed(2) }} {{ currency }}</div>
            </div>
          </div>
          <button v-if="requirePayment" class="btn btn-lg btn-block mt-2 button-hover" :style="buttonStyles.primary" v-on:click="order()">{{ $t('orderProceed') }}</button>
          <button v-else class="btn btn-lg btn-block mt-2 button-hover" :style="buttonStyles.primary" v-on:click="order()">{{ $t('orderConfirm') }}</button>
          <div class="mt-3">
            {{ $t('orderTerms') }}
            <br /><a href="https://www.framme.com/legal-stuff/" target="_blank">{{ $t('readTermsAndConds') }}</a>
          </div>
        </div>
      </div>
      <div v-if="orderData.payment_method === 2" class="row mt-4">
        <div class="col-12 col-md-6 offset-md-3 pt-3">
          <div class="font-weight-bold">{{ $t('summary') }}</div>
          <div class="d-flex justify-content-between">
            <div>Products</div>
            <div>{{ subtotalPrice.toFixed(2) }} {{ currency }}</div>
          </div>
          <div class="d-flex justify-content-between">
            <div>{{ $t('delivery') }}</div>
            <div>{{ adjustedDeliveryFee.toFixed(2) }} {{ currency }}</div>
          </div>
          <div class="d-flex justify-content-between font-weight-bold mb-3">
            <div>{{ $t('orderTotal') }}</div>
            <div>{{ totalPrice.toFixed(2) }} {{ currency }}</div>
          </div>
          <button class="btn btn-lg btn-block mt-2 button-hover"
                  :style="buttonStyles.primary"
                  v-on:click="order()"
          >{{ $t('orderConfirm') }}</button>
          <div class="mt-3">
            {{ $t('orderTerms') }}
            <br /><a href="https://www.framme.com/legal-stuff/" target="_blank">{{ $t('readTermsAndConds') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { requiredIf, required, email, maxLength, helpers } from 'vuelidate/lib/validators'
import SelectProductModal from './SelectProductModal.vue'

export default {
  name: 'CustomerSelectPage',
  props: {
    products: Array,
    productSets: Array,
    aProductsCount: Number,
    maxCount: Number,
    countryList: Array,
    questionIncluded: Boolean,
    questionMandatory: Boolean,
    questionText: String,
    allowAttachment: Boolean,
    requirePayment: Boolean,
    allowInvoice: Boolean,
    currency: String,
    limit_addresses: Boolean,
    addresses: Array,
    headerStyles: Object,
    containerStyles: Object,
    buttonStyles: Object,
    limitType: Number,
    selectedCount: Number,
    orderBudget: Number,
    isNegativeOrderBudget: Boolean,
    deliveryFee: Number,
    totalPrice: Number,
  },
  components: {
    SelectProductModal,
  },
  data() {
    return {
      orderData: {
        address: null,
        product_data: [],
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        street: null,
        city: null,
        zip: null,
        country: "",
        answer: null,
        total_price: 0.00,
        payment_method: 1,
        company_name: null,
        company_vat_number: null,
        company_invoice_email: null
      },
      subtotalPrice: 0.00,
      updating: false,
      showMoreInfo: false,
      moreInfoProduct: null,
      moreInfoProdInCart: [],
      attachment: null,
      modalProductSetIdx: null,
      productSetData: [],
      legacyProductsData: []
    }
  },
  validations: {
      orderData: {
      address: {
        required: requiredIf(function(){
          return this.limit_addresses === true || (this.limit_addresses === null && this.orderData.address)
        }),
      },
      first_name: {
        required,
        latinChars: helpers.regex(
            'latinChars',
            /^[a-zA-Zäöåéüßžšûœøáíóñ -]+$/
        )
      },
      last_name: {
        required,
        latinChars: helpers.regex(
            'latinChars',
            /^[a-zA-Zäöåéüßžšûœøáíóñ -]+$/
        )
      },
      email: {
        email,
        required,
        latinChars: helpers.regex(
            'latinChars',
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/
        )
      },
      phone: {
        required,
        phoneNumber: helpers.regex(
            'phoneNumber',
            /^\+?\d+$/
        )
      },
      street: {
        required: requiredIf(function(){
          if (this.limit_addresses === true) {
            return this.orderData.address === null;
          } else {
            return this.orderData.address === 'new' || this.orderData.address === null;
          }
        }),
        maxLength: maxLength(45),
        latinChars: helpers.regex(
            'latinChars',
            /^[a-zA-Z0-9äöåéüßžšûœøáíóñ .,-]+$/
        )
      },
      city: {
        required: requiredIf(function(){
          if (this.limit_addresses === true) {
            return this.orderData.address === null;
          } else {
            return this.orderData.address === 'new' || this.orderData.address === null;
          }
        }),
        latinChars: helpers.regex(
            'latinChars',
            /^[a-zA-Zäöåéüßžšûœøáíóñ -]+$/
        )
      },
      zip: {
        required: requiredIf(function(){
          if (this.limit_addresses === true) {
            return this.orderData.address === null;
          } else {
            return this.orderData.address === 'new' || this.orderData.address === null;
          }
        }),
        maxLength: maxLength(12),
        latinChars: helpers.regex(
            'latinChars',
            /^[A-Za-z0-9\s-]+$/
        )
      },
      country: {
        required: requiredIf(function(){
          if (this.limit_addresses === true) {
            return this.orderData.address === null;
          } else {
            return this.orderData.address === 'new' || this.orderData.address === null;
          }
        }),
        latinChars: helpers.regex(
            'latinChars',
            /^[a-zA-Z ,()-]+$/
        )
      },
      company_name: {
        required: requiredIf(function() {
          return this.orderData.payment_method === 2
        })
      },
      company_vat_number: {
        required: requiredIf(function() {
          return this.orderData.payment_method === 2
        })
      },
      company_invoice_email: {
        required: requiredIf(function() {
          return this.orderData.payment_method === 2
        }),
        email
      }
    }
  },
  created() {
    for (let i = 0; i < this.filteredProductSets.length; i++) {
      this.productSetData.push([])
    }
  },
  beforeMount: function() {
    this.orderData.total_price = this.totalPriceWithDeliveryFee
    //this.$emit('updateTotalPrice', this.totalPriceWithDeliveryFee)
  },
  computed: {
    maxSelected(){
      if(this.limitType == 3){
        return false;
      }
      return this.legacyProductsData.length >= this.aProductsCount;
    },
    countries(){
      const countries = this.countryList.map(c => ({name: c[1], value: c[0]}))
      // remove duplicates
      return countries.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i);
    },
    aProducts(){
      // Check if product has variants and if it does, check that at least some of those variants are in stock
      return this.products.filter(p => p.variants && p.variants.length > 0 ? !p.variants.every(v => v.in_stock == false) : true)
    },
    filteredProductSets() {
      let filteredProductSets = []
      for (let ps of this.productSets) {
        ps.products = [...ps.products.filter(p => p.variants && p.variants.length > 0 ? !p.variants.every(v => v.in_stock == false) : true)]
        ps.max_order_count = ps.max_order_count > ps.products.length ? ps.products.length : ps.max_order_count
        filteredProductSets.push(ps)
      }
      return filteredProductSets
    },
    selectedAddress(){
      if(this.orderData.address){
        return this.addresses.find(a => a.id == this.orderData.address);
      }
      return false;
    },
    streetAddress(){
      return this.selectedAddress.address_extra ? this.selectedAddress.address + ', ' + this.selectedAddress.address_extra : this.selectedAddress.address;
    },
    modalProductIsSelected() {
      if(this.moreInfoProduct && this.modalProductSetIdx !== null) {
        const found = this.productSetData[this.modalProductSetIdx].find(psData => psData.productId === this.moreInfoProduct.id)
        if (found) {
          return true;
        }
      } else if (this.moreInfoProduct) {
        const found = this.legacyProductsData.find(p => p.productId == this.moreInfoProduct.id)
        if(found) {
          return true;
        }
      }
      return false;
    },
    productIdsInOrder(){
      return this.legacyProductsData.map(p => p.productId);
    },
    adjustedAddresses() {
      if (this.limit_addresses === null) {
        return [...this.addresses, {name: 'Other address', id: 'new'}]
      }
      return this.addresses
    },
    adjustedDeliveryFee() {
      if (this.limit_addresses === false || (this.limit_addresses === null && this.orderData.address === 'new')) {
        return this.deliveryFee
      } else {
        return 0.00
      }
    },
    totalPriceWithDeliveryFee() {
      return this.subtotalPrice + this.adjustedDeliveryFee
    },
    dropdownBackgroundColor() {
      let backgroundColor = ''
      if(this.containerStyles['background-color'].length === 9) {
        backgroundColor = this.containerStyles['background-color'].slice(0, -2);
      }else{
        backgroundColor = this.containerStyles['background-color']
      }
      const color = this.containerStyles['color']
      return {backgroundColor : backgroundColor , color : color}
    },
    dropdownHighlight() {
      const backgroundColor = this.containerStyles['color']
      let color = ''
      if(this.containerStyles['background-color'].length === 9){
        color = this.containerStyles['background-color'].slice(0, -2);
      }else{
        color = this.containerStyles['background-color']
      }
      return {backgroundColor : backgroundColor , color : color}
    }
  },
  watch: {
    adjustedDeliveryFee: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('updateTotalPrice', this.totalPriceWithDeliveryFee)
      }
    },
  },
  methods:{
    addToOrder(data, productSetIdx) {
      const toAdd = data.filter(p => p.quantity > 0)
      if (toAdd.length > 0 && productSetIdx === null) {
        this.legacyProductsData = [...this.legacyProductsData, ...toAdd];
      } else if (toAdd.length > 0) {
        this.productSetData[productSetIdx] = [...this.productSetData[productSetIdx], ...toAdd]
      }

      // update product counter
      let productCount = 0;
      if(this.legacyProductsData.length > 0){
        productCount += this.legacyProductsData.map(p => p.quantity).reduce((x, y) => x + y)
      }
      if (this.productSetData.some(ps => ps.length > 0)) {
        productCount += this.productSetData.flat().map(p => p.quantity).reduce((x, y) => x + y)
      }
      this.$emit('updateSelectedCount', productCount);
      // update total if payment enabled(?)
      let priceObj = {};
      this.products.filter(p => this.productIdsInOrder.includes(p.id)).map(p => priceObj[p.id] = Number(p.price))

      const sums = this.legacyProductsData.map(p => p.quantity * priceObj[p.productId])
      sums.push(...this.productSetSums())

      this.subtotalPrice = sums.length > 0 ? sums.reduce((x, y) => x + y) : 0.00;
      const total = this.subtotalPrice + this.adjustedDeliveryFee

      this.orderData.total_price = total;
      this.$emit('updateTotalPrice', total);
      this.closeMoreInfoModal();
    },
    updateOrder(data, productSetIdx){
      if (productSetIdx !== undefined && productSetIdx !== null) {
        if(this.limitType === 1){
          // allowed to select only one per product
          // remove old selection if any
          const item = data[0]
          this.productSetData[productSetIdx] = this.productSetData[productSetIdx].filter(p => p.productId != item.productId);

          // add new selection
          this.productSetData[productSetIdx].push(item);
        } else {
          // loop all variants from data
          for (const item of data) {
            // check if it's already in cart
            let found = this.productSetData[productSetIdx].find(p => p.productId == item.productId && p.variantId == item.variantId)
            if(found){
              // if it is, update it
              found.quantity = item.quantity;
            } else if(item.quantity > 0){
              // if it's not, add the variant to cart if q > 1
              this.productSetData[productSetIdx].push(item);
            }
          }
        }
        // at the end remove all rows that were updated to 0
        this.productSetData[productSetIdx] = this.productSetData[productSetIdx].filter(p => p.quantity != 0);
      } else {
        if(this.limitType === 1){
          // allowed to select only one per product
          // remove old selection if any
          const item = data[0]
          this.legacyProductsData = this.legacyProductsData.filter(p => p.productId != item.productId);

          // add new selection
          this.legacyProductsData.push(item);
        } else {
          // loop all variants from data
          for (const item of data) {
            // check if it's already in cart
            let found = this.legacyProductsData.find(p => p.productId == item.productId && p.variantId == item.variantId)
            if(found){
              // if it is, update it
              found.quantity = item.quantity;
            } else if(item.quantity > 0){
              // if it's not, add the variant to cart if q > 1
              this.legacyProductsData.push(item);
            }
          }
        }
        // at the end remove all rows that were updated to 0
        this.legacyProductsData = this.legacyProductsData.filter(p => p.quantity != 0);
      }

      // update product counter
      let productCount = 0;
      if(this.legacyProductsData.length > 0){
        productCount += this.legacyProductsData.map(p => p.quantity).reduce((x, y) => x + y)
      }
      if (this.productSetData.some(ps => ps.length > 0)) {
        productCount += this.productSetData.flat().map(p => p.quantity).reduce((x, y) => x + y)
      }
      this.$emit('updateSelectedCount', productCount);
      // update total if payment enabled(?)
      let priceObj = {};
      this.products.filter(p => this.productIdsInOrder.includes(p.id)).map(p => priceObj[p.id] = Number(p.price))
      const sums = this.legacyProductsData.map(p => p.quantity * priceObj[p.productId])
      sums.push(...this.productSetSums())

      this.subtotalPrice = sums.length > 0 ? sums.reduce((x, y) => x + y) : 0.00;
      const total = this.subtotalPrice + this.adjustedDeliveryFee

      this.orderData.total_price = total;
      this.$emit('updateTotalPrice', total);
      this.closeMoreInfoModal();
    },
    removeFromOrder(data, productSetIdx){
      const productId = data.productId
      //this.orderData.products = this.orderData.products.filter(p => p != productId)
      if (productSetIdx !== undefined && productSetIdx !== null) {
        this.productSetData[productSetIdx] = this.productSetData[productSetIdx].filter(p => p.productId != productId)
      } else {
        this.legacyProductsData = this.legacyProductsData.filter(p => p.productId != productId)
      }
      // update product counter
      let productCount = 0
      if(this.legacyProductsData.length > 0){
        productCount += this.legacyProductsData.map(p => p.quantity).reduce((x, y) => x + y)
      }
      if (this.productSetData.some(ps => ps.length > 0)) {
        productCount += this.productSetData.flat().map(p => p.quantity).reduce((x, y) => x + y)
      }
      this.$emit('updateSelectedCount', productCount);

      // update total if payment enabled
      let priceObj = {};
      this.products.filter(p => this.productIdsInOrder.includes(p.id)).map(p => priceObj[p.id] = Number(p.price))
      const sums = this.legacyProductsData.map(p => p.quantity * priceObj[p.productId])
      sums.push(...this.productSetSums())

      this.subtotalPrice = sums.length > 0 ? sums.reduce((x, y) => x + y) : 0.00;
      const total = this.subtotalPrice + this.adjustedDeliveryFee

      this.orderData.total_price = total;
      this.$emit('updateTotalPrice', total);
      this.closeMoreInfoModal();
    },
    getImageUrl(path){
      if(!path){
        return "";
      }
      if(path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
    order(){
      const vm = this;
      this.updating = true;
      let notOk = false;
      this.$v.$touch();
      if(this.$v.$invalid) {
        notOk = true;
      }
      if(this.selectedCount === 0){
        vm.$toastr.e(
          vm.$t('orderNoItemsSelectedError')
        );
        notOk = true;
      }
      if(this.limitType === 1 && this.selectedCount > this.maxCount){
        vm.$toastr.e(
          vm.$t('orderToManyItemsSelectedError')
        );
        notOk = true;
      }
      if (this.limitType === 2 && this.isNegativeOrderBudget) {
        vm.$toastr.e(
          this.$t('orderBudgetError', { budget: this.orderBudget, currency: this.currency })
        );
        notOk = true;
      }
      if(notOk){
        this.updating = false;
        return;
      }

      let data = this.orderData;

      // collect all products in product_data
      if (this.legacyProductsData.length > 0 || this.productSetData.some(psData => psData.length > 0)) {
        data.product_data = [...this.legacyProductsData, ...this.productSetData.flat()]
      }
      let url = process.env.VUE_APP_API_URL + '/api/campaigns/open/' + this.$route.params.token + "/";
      if(this.requirePayment && this.orderData.payment_method == 1){
        // Go to Stripe payment flow only if credit card is selected
        url = process.env.VUE_APP_API_URL + '/api/campaigns/open/' + this.$route.params.token + "/checkout/";
      }
      const method = 'POST';

      let headers = {};
      if(this.attachment && this.attachment.name){
        headers['Content-Type'] = 'multipart/form-data';
        data = this.JSONToFormData(data);
      } else {
        headers['Content-Type'] = 'application/json';
      }

      this.$http({
        method: method,
        url: url,
        data: data,
        headers: headers
      })
      .then(function (response) {
        if(vm.requirePayment && vm.orderData.payment_method == 1){
          // forward to redirect url
          window.open(response.data.redirect_url, '_self');
        } else {
          vm.updating = false;
          const allProducts = [...vm.products, ...vm.productSets.map(ps => ps.products).flat()]
          const prod = allProducts.find(p => p.id == vm.orderData.product_data[0].productId)
          const image = vm.getImageUrl(prod.primary_thumb_big)
          // redirect to thanks page
          vm.$emit('showThanksPage', {image: image})
          vm.$toastr.s(
            vm.$t('orderSentSuccessfully')
          );
        }
      })
      .catch(function (error) {
        let isLinkDeactivatedToastShown = false;
        if (error.response && error.response.data && error.response.status === 404) {
          const errorMessage = error.response.data.error;
          if (errorMessage === "linkAlreadyDeactivated") {
            vm.$toastr.e(vm.$t('linkAlreadyDeactivated'));
            isLinkDeactivatedToastShown = true;
            setTimeout(function () {
              isLinkDeactivatedToastShown = false;
              location.reload()
              }, 3500);
          } else {
            vm.$toastr.e(errorMessage);
          }
        }
        else if (error.request){
          const parsedData = JSON.parse(error.request.responseText);
          const errorMessage = parsedData.error;
          vm.$toastr.e(errorMessage);
        }
        else {
          vm.$toastr.e(
          error
        );
        }
        if (!isLinkDeactivatedToastShown) {
          vm.updating = false;
        }
      });
    },
    showModal(productId, productSetIdx) {
      if (productSetIdx !== undefined && productSetIdx !== null) {
        if (this.maxSelectedInSet(productSetIdx)) {
          if (!this.productSelectedInSet(productId, productSetIdx)) {
            return;
          }
        }
        this.moreInfoProduct = this.filteredProductSets[productSetIdx].products.find(p => p.id == productId);
        this.moreInfoProdInCart = this.productSetData[productSetIdx].filter(p => p.productId == productId)
        this.modalProductSetIdx = productSetIdx
        this.showMoreInfo = true;
      } else {
        if(this.maxSelected) {
          // If max selected, allow only editing
          if(!this.productIdsInOrder.includes(productId)) {
            return;
          }
        }
        this.moreInfoProduct = this.aProducts.find(p => p.id == productId);
        this.moreInfoProdInCart = this.legacyProductsData.filter(p => p.productId == productId)
        this.showMoreInfo = true;
      }
    },
    closeMoreInfoModal(){
      this.moreInfoProduct = null;
      this.moreInfoProdInCart = [];
      this.modalProductSetIdx = null
      this.showMoreInfo = false;
    },
    handleFileUpload(){
      if(this.$refs.attachment.files.length == 0){
        return;
      }
      if(this.$refs.attachment.files[0].size > 5000000){
        this.toastr.error(this.$t('orderFileSizeError', {filesize: 5}));
        return;
      }
      let file = this.$refs.attachment.files[0];
      if(file.name.length > 80){
        // Make sure the filename is max 80char + extension
        let newFileName = file.name.substring(0,file.name.lastIndexOf(".")).substring(0,80);
        const fileExt = file.name.substring(file.name.lastIndexOf(".")+1)
        if(!file.name.lastIndexOf(".")){
          // no dot in filename, just shorten the string
          newFileName = file.name.substring(0,80);
        } else {
          // add file extension to name
          newFileName += "." + fileExt;
        }
        // replace the file with new one to update the name
        this.attachment = new File([file], newFileName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      } else {
        this.attachment = file;
      }
    },
    removeAttachment(){
      this.attachment = null;
    },
    JSONToFormData(data){
      let orderFormData = new FormData();
      orderFormData.append('attachment', this.attachment);
      for(var key in data){
        if(Array.isArray(data[key])){
          orderFormData.append(key, JSON.stringify(data[key]));
        }else if(key!='primary_image' && data[key] != null){
          orderFormData.append(key, data[key]);
        }
      }
      return orderFormData;
    },
    productSelectedInSet(productId, idx) {
      if (this.productSetData[idx] === undefined || this.productSetData[idx].length === 0) {
        return false;
      }
      return this.productSetData[idx].map(psData => psData.productId).includes(productId)
    },
    maxSelectedInSet(idx) {
      if(this.limitType !== 1 || this.productSetData[idx] === undefined) {
        return false;
      }
      return this.productSetData[idx].length >= this.filteredProductSets[idx].max_order_count
    },
    productSetSums() {
      let sum = []
      for (let i = 0; i < this.productSetData.length; i++) {
        for (const p of this.filteredProductSets[i].products) {
          const psData = this.productSetData[i].filter(psData => psData.productId === p.id)
          if (psData !== undefined && psData.length > 0) {
            sum.push(psData.reduce((totalPrice, psData) => {
              return totalPrice + (psData.quantity * p.price)
            }, 0))
          }
        }
      }
      return sum
    }
  }
}

</script>
<style>
.tooltip-inner {
    background-color: #E4E4E4;
    color: #3a3a3a;
}
.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #E4E4E4 !important;
}
.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: #E4E4E4 !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #E4E4E4 !important;
}
.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #E4E4E4 !important;
}

.mySelect .vs__search,
.mySelect .vs__search::placeholder,
.mySelect .vs__dropdown-toggle,
.mySelect .vs__dropdown-option,
.mySelect .vs__selected,
.mySelect .vs__dropdown-menu {
  background-color: var(--dropdown-bg-color);
  color: var(--dropdown-color);
}

.mySelect .vs__dropdown-option--selected,
.mySelect .vs__dropdown-option--highlight {
  background-color: var(--dropdown-highlight-bg);
  color: var(--dropdown-highlight-color);
 }

.mySelect .vs__open-indicator,
.mySelect .vs__open-indicator--disabled {
  fill: var(--dropdown-highlight-bg);
  background-color: var(--dropdown-highlight-color)
}

.custom-file-upload-question {
  font-family: "Volte-Semibold";
  font-weight: normal;
  border: 1px solid #f0f0f0;
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.custom-file-upload-question:hover {
  opacity: 0.8;
}
</style>