<template>
  <div v-if="!tokenChecked">
    <div class="loader-container">
      <div class="row justify-content-center">
        <div class="col-md-4 d-flex justify-content-center">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="tokenIsInvalid || (maxCountFromProductSets === 0 && aProductsCount === 0)">
    <div class="container">
      <InvalidToken />
    </div>
  </div>
  <div v-else>
    <CustomerTopNav
      v-bind:storeLogo=logo
      v-bind:showCount=showSelectPage
      v-bind:limitType=limitType
      v-bind:maxCount=maxCount
      v-bind:selectedCount=selectedCount
      v-bind:requirePayment=data.require_payment
      v-bind:totalPrice=totalPrice
      v-bind:orderBudget=orderBudget
      v-bind:orderBudgetRemaining=orderBudgetRemaining
      v-bind:currency=data.currency
    />
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-4 pb-4" :style="containerStyles">
      <CustomerIntroPage v-if="showIntroPage" @toSelectPage="selectPage = true;"
        v-bind:expiryDate=parseDate(data.expiration_date)
        v-bind:imageUrl=mainImageUrl
        v-bind:header=data.intro_header
        v-bind:text=data.intro_text
        v-bind:signature=data.intro_signature
        v-bind:headerStyles=headerStyles
        v-bind:buttonStyles=buttonStyles
        v-bind:isMultiInvitation=data.is_multi_invitation
      />
      <CustomerSelectPage v-else-if="showSelectPage"
        @updateSelectedCount="updateSelectedCount"
        @updateTotalPrice="updateTotalPrice"
        @showThanksPage="showThanksPage"
        v-bind:products=data.products
        v-bind:productSets=data.product_sets
        v-bind:limitType=limitType
        v-bind:aProductsCount=aProductsCount
        v-bind:maxCount=maxCount
        v-bind:orderBudget=orderBudget
        v-bind:isNegativeOrderBudget=isNegativeOrderBudget
        v-bind:countryList=data.countries
        v-bind:questionIncluded=data.question_included
        v-bind:questionMandatory=data.question_mandatory
        v-bind:questionText=data.question_text
        v-bind:allowAttachment=data.allow_attachment
        v-bind:requirePayment=data.require_payment
        v-bind:allowInvoice=data.allow_invoice
        v-bind:currency=data.currency
        v-bind:limit_addresses=data.limit_addresses
        v-bind:addresses=data.addresses
        v-bind:containerStyles="containerStyles"
        v-bind:headerStyles=headerStyles
        v-bind:buttonStyles=buttonStyles
        v-bind:selectedCount=selectedCount
        v-bind:deliveryFee=data.delivery_fee
        v-bind:totalPrice=totalPrice
      />
      <CustomerThanksPage v-else-if="thankYouPage"
        v-bind:imageUrl=thanksImageUrl
        v-bind:header=data.thanks_header
        v-bind:text=data.thanks_text
        v-bind:signature=data.thanks_signature
        v-bind:headerStyles=headerStyles
        v-bind:actionButtons=actionButtons
        v-bind:primaryActionButtonStyles=primaryActionButtonStyles
        v-bind:secondaryActionButtonStyles=secondaryActionButtonStyles
        v-bind:isMultiInvitation=data.is_multi_invitation
      />
    </div>
  </div>
</template>

<script>
import CustomerTopNav from '../components/CustomerTopNav.vue'
import CustomerIntroPage from '../components/CustomerIntroPage.vue'
import CustomerSelectPage from '../components/CustomerSelectPage.vue'
import CustomerThanksPage from '../components/CustomerThanksPage.vue'
import InvalidToken from '../components/InvalidToken.vue'

export default {
  name: 'CustomerCampaign',
  components: {
    CustomerTopNav,
    CustomerIntroPage,
    CustomerSelectPage,
    CustomerThanksPage,
    InvalidToken,
  },
  data() {
    return {
      tokenChecked: false,
      tokenIsInvalid: false,
      updating: false,
      products: [],
      data: {},
      token: null,
      selectPage: false,
      thankYouPage: false,
      thanksImageUrl: "",
      prodNames: [],
      selectedCount: 0,
      totalPrice: 0.00
    }
  },
  created: function () {
    this.getCampaignWithToken()
  },
  computed:{
    containerStyles() {
      let values = {}
      if(this.data.template){
        if(this.data.template.container_bg_color){
          values['background-color'] = this.data.template.container_bg_color;
        }
        if(this.data.template.font_color_body){
          values['color'] = this.data.template.font_color_body;
        }
      }
      return values;
    },
    transparentContainerStyles() {
      let values = {}
      if(this.mainImageUrl !== null) {
        values['background-color'] = "#00000000";
      }else{
        if(this.data.template.container_bg_color){
          values['background-color'] = this.data.template.container_bg_color;
        }
      }
      if(this.data.template.font_color_body){
        values['color'] = this.data.template.font_color_body;
      }
      return values;
    },
    headerStyles() {
      let values = {}
      if(this.data.template){
        if(this.data.template.font_color_title){
          values['color'] = this.data.template.font_color_title;
        }
      }
      return values;
    },
    buttonStyles(){
      let styles = {
        primary: {},
        secondary: {}
      }

      if(this.data.template){
        // primary button
        if(this.data.template.button_primary_bg_color){
          styles.primary['background-color'] = this.data.template.button_primary_bg_color;
        }
        if(this.data.template.button_primary_border_color){
          styles.primary['border-color'] = this.data.template.button_primary_border_color;
        }
        if(this.data.template.button_primary_font_color){
          styles.primary['color'] = this.data.template.button_primary_font_color;
        }
        // secondary button
        if(this.data.template.button_secondary_bg_color){
          styles.secondary['background-color'] = this.data.template.button_secondary_bg_color;
        }
        if(this.data.template.button_secondary_border_color){
          styles.secondary['border-color'] = this.data.template.button_secondary_border_color;
        }
        if(this.data.template.button_secondary_font_color){
          styles.secondary['color'] = this.data.template.button_secondary_font_color;
        }
        if(this.data.template.button_border_radius){
          styles.primary['border-radius'] = this.data.template.button_border_radius;
          styles.secondary['border-radius'] = this.data.template.button_border_radius;
        }
      }
      return styles;
    },
    showIntroPage(){
      return !this.selectPage && !this.thankYouPage;
    },
    showSelectPage(){
      return this.selectPage && !this.thankYouPage;
    },
    mainImageUrl(){
      if(this.data.intro_hero){
        return this.data.intro_hero;
      }
      if (this.aProducts.length > 0 && this.aProducts[0].primary_image) {
        let path = this.aProducts[0].primary_image;
        if(path && path.length > 0){
          if(path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
            return path;
          } else {
            return process.env.VUE_APP_IMAGE_HOST + path;
          }
        } else {
          return null;
        }
      } else if (this.filteredProductSets.length > 0 && this.filteredProductSets[0].products.length > 0) {
        let path = this.filteredProductSets[0].products[0].primary_image;
        if(path && path.length > 0){
          if(path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
            return path;
          } else {
            return process.env.VUE_APP_IMAGE_HOST + path;
          }
        } else {
          return null;
        }
      }
      return null
    },
    aProducts(){
      // Check if product has variants and if it does, check that at least some of those variants are in stock
      if(this.data.products){
        return this.data.products.filter(p => p.variants && p.variants.length > 0 ? !p.variants.every(v => v.in_stock == false) : true)
      }
      return []
    },
    filteredProductSets() {
      if (this.data.product_sets) {
        return this.data.product_sets.filter(ps => ps.products.some(p => p.variants && p.variants.length > 0 ? !p.variants.every(v => v.in_stock == false) : true))
      }
      return []
    },
    maxCountFromProductSets() {
      if(this.data.product_sets && this.data.product_sets.length > 0) {
        const filteredProductSets = this.data.product_sets.filter(ps => ps.products.some(p => p.variants && p.variants.length > 0 ? !p.variants.every(v => v.in_stock == false) : true))
        const countList =  filteredProductSets.map(ps => ps.max_order_count < ps.products.length ? ps.max_order_count : ps.products.length)
        return countList.length > 0 ? countList.reduce((x, y) => x + y) : 0
      }
      return 0
    },
    aProductsCount() {
      return this.data.max_order_count < this.aProducts.length ? this.data.max_order_count : this.aProducts.length;
    },
    maxCount(){
      return this.aProductsCount + this.maxCountFromProductSets
    },
    limitType(){
      return this.data.order_limit_type;
    },
    orderBudget() {
      return this.limitType === 2 && this.data.order_budget ? this.data.order_budget : 0
    },
    orderBudgetRemaining() {
      const remains = this.orderBudget - this.totalPrice
      return remains > 0 ? remains : 0
    },
    isNegativeOrderBudget() {
      return this.orderBudget - this.totalPrice < 0
    },
    logo(){
      if(this.data.template.logo){
        let url = this.data.template.logo;
        if(url.startsWith(process.env.VUE_APP_IMAGE_HOST)){
          return url;
        } else {
          return process.env.VUE_APP_IMAGE_HOST + url;
        }
      }
      return this.data.store_logo
    },
    actionButtons() {
      const { primary_action_text, primary_action_url, secondary_action_text, secondary_action_url } = this.data
      const buttons = {}

      if (primary_action_text && primary_action_url) {
        buttons.primary = {
          text: primary_action_text,
          url: primary_action_url
        }
      }

      if (secondary_action_text && secondary_action_url) {
        buttons.secondary = {
          text: secondary_action_text,
          url: secondary_action_url
        }
      }

      return Object.keys(buttons).length > 0 ? buttons : null
    },
    primaryActionButtonStyles() {
      const styles = {
        'background-color': '#222222',
        'color': '#FFFFFF',
        'border-color': '#222222',
        'border-radius': '3px'
      };
      const { template } = this.data;

      if (template) {
        if (template.button_primary_bg_color) {
          styles['background-color'] = template.button_primary_bg_color
        }
        if (template.button_primary_font_color) {
          styles['color'] = template.button_primary_font_color
        }
        if (template.button_primary_border_color) {
          styles['border-color'] = template.button_primary_border_color
        }
        if (template.button_border_radius) {
          styles['border-radius'] = template.button_border_radius
        }
      }

      return styles;
    },
    secondaryActionButtonStyles() {
      const styles = {
        'background-color': '#D9D9D7',
        'color': '#222222',
        'border-color': '#D9D9D7',
        'border-radius': '3px'
      };
      const { template } = this.data

      if (template) {
        if (template.button_secondary_bg_color) {
          styles['background-color'] = template.button_secondary_bg_color
        }
        if (template.button_secondary_font_color) {
          styles['color'] = template.button_secondary_font_color
        }
        if (template.button_secondary_border_color) {
          styles['border-color'] = template.button_secondary_border_color
        }
        if (template.button_border_radius) {
          styles['border-radius'] = template.button_border_radius
        }
      }

      return styles
    }
  },
  methods: {
    getCampaignWithToken(){
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/campaigns/open/' + this.$route.params.token + "/";
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
      .then(function (response) {
        if(response.data.template){
          vm.$store.dispatch('setCustomerCampaignTemplate', response.data.template);
        }
        if (response.data.favicon) {
              document.querySelector('link[rel="icon"]').href = response.data.favicon
            }
            else {
              document.querySelector('link[rel="icon"]').href = `${process.env.BASE_URL}favicon.ico`
            }
            if (response.data.title) {
              document.getElementById("store-title").innerText = response.data.title
              document.getElementById("og-store-title").content = response.data.title
            }
            if (response.data.description) {
              document.getElementById("store-description").content = response.data.description
              document.getElementById("og-store-description").content = response.data.description
            }
        vm.data = response.data;
        vm.$i18n.locale = response.data.language;
        vm.tokenChecked = true;
      })
      .catch(function (error) {
        if(error.response.data && error.response.data.language){
          vm.$i18n.locale = error.response.data.language;
        }
        vm.tokenIsInvalid = true;
        vm.tokenChecked = true;
        vm.updating = false;
      });
    },
    parseDate(date_str){
      const date = new Date(date_str);
      const month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
    updateSelectedCount(sCount){
      this.selectedCount = sCount;
    },
    updateTotalPrice(total){
      this.totalPrice = total;
    },
    showThanksPage(data){
      this.thanksImageUrl = data.image;
      this.thankYouPage = true;
      this.selectPage = false;
    }
  }
}

</script>
