<template>
  <div>
    <div class="modal fade in show modal-active modal-open modal-select-product" id="archiveProductModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" :style="dropdownBackgroundColor" style="border: 1px solid #dee2e6">
          <div class="modal-header">
            <h4 class="modal-title" :style="headerStyles">{{ $t('orderViewInfo') }}</h4>
            <button type="button" class="close" v-on:click="closeModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body mt-4">
            <div class="row">
              <div class="col-12 col-md-6">
                <ProductPictureGallery v-bind:primaryImage=primaryImage v-bind:images=product.images />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0">
                <div class="font-weight-bold">{{product.name}}</div>
                <div v-if="requirePayment" class="font-weight-bold font-grey">{{product.price}} {{currency}}</div>
                <div v-if="$t(deliveryTime).includes($t('orderDeliveryIn'))">{{ $t(deliveryTime) }}</div>
                <div v-else>{{ $t('orderDeliveryIn') }} {{ $t(deliveryTime) }}</div>
                <div class="font-weight-bold mt-3">{{ $t('orderProductDescription') }}</div>
                <div v-html="withBR(product.description)"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6" v-if="hasVariants && limitType === 1">
                <div>
                  <div class="font-weight-bold mt-3">{{ $t('orderSelectVariants') }}*</div>
                  <v-select
                    label="name"
                    v-model="selectedVariant"
                    :options="product.variants.filter(v => v.in_stock)"
                    :reduce="v => v.id"
                    :placeholder="$t('orderSelectVariant')"
                    :clearable=false
                    class="mySelect"
                    :style="{ '--dropdown-bg-color': dropdownBackgroundColor.backgroundColor, '--dropdown-color': dropdownBackgroundColor.color,
                    '--dropdown-highlight-bg': dropdownHighlight.backgroundColor, '--dropdown-highlight-color': dropdownHighlight.color }"
                  ></v-select>
                  <div class="form-field-error" v-if="showWarning">
                    Please select one
                  </div>
                </div>
              </div>
              <div class="col-12" v-else-if="limitType === 2 || limitType === 3">
                <div v-if="hasVariants">
                  <div class="font-weight-bold mt-3">{{ $t('orderSelectVariants') }}*</div>
                  <div class="row mt-md-3">
                    <div class="col-12 col-md-3 mb-4" v-for="variant in variantOpts" v-bind:key="variant.id">
                      <div class="font-weight-bold">{{variant.name}}</div>
                      <div v-if="product.stock_management" class="font-12 product-page-stock">{{variant.stock_quantity}} {{ $t('orderStock') }}</div>
                      <div><input type="number" :style="containerStyles" class="inputfield" min="0" v-model.number="variant.quantity" /></div>
                      <div class="form-field-error" v-if="product.stock_management && (variant.quantity > variant.stock_quantity)">
                        {{ $t('notEnoughInStock') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="font-weight-bold mt-3">{{ $t('orderQuantity') }}</div>
                  <div><input type="number" :style="containerStyles" class="inputfield" min="0" v-model.number="variantOpts[0].quantity" /></div>
                </div>
              </div>
              <div class="col-12 col-md-6" v-if="requirePayment || limitType === 2">
                <div v-if="limitType === 1" class="font-weight-bold mt-3 mt-md-5">{{ $t('orderSubtotal') }}: {{product.price}} {{currency}}</div>
                <div v-else-if="limitType === 2 || limitType === 3" class="font-weight-bold mt-2 mt-md-3">{{ $t('orderSubtotal') }}: {{localTotal}}  {{currency}}</div>
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
            <div class="row w-100">
              <div v-if="!isSelected" class="col-0 col-md-6"></div>
              <div v-if="!isSelected" class="col-12 col-md-3 px-0 px-md-3">
                <button type="button" class="btn btn-lg  mt-2 w-100 button-hover" :class="selectedCount ? '' : 'opacity-05'" :style="buttonStyles.primary" v-on:click="addToOrder()">{{ $t('OrderAdd') }}</button>
              </div>
              <div v-if="isSelected" class="col-0 col-md-3"></div>
              <div v-if="isSelected" class="col-12 col-md-3 px-0 px-md-3"><button v-if="hasVariants || limitType === 2 || limitType === 3" type="button" class="btn btn-lg mt-2 w-100 button-hover" :style="buttonStyles.primary" v-on:click="updateOrder()">{{ $t('saveChanges') }}</button></div>
              <div v-if="isSelected" class="col-12 col-md-3 px-0 px-md-3"><button type="button" class="btn btn-lg mt-2 w-100 button-hover" :style="buttonStyles.secondary" v-on:click="removeFromOrder()">{{ $t('orderRemove') }}</button></div>
              <div class="col-12 col-md-3 px-0 px-md-3"><button type="button" class="btn btn-lg mt-2 w-100 button-hover" :style="buttonStyles.secondary" v-on:click="closeModal()">{{ $t('orderCancel') }}</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>  
</template>

<script>

import ProductPictureGallery from '@/modules/general/components/ProductPictureGallery.vue'

export default {
  name: "SelectProductModal",
  components: {
    ProductPictureGallery
  },
  props: [
    "product",
    "inCart",
    "requirePayment",
    "currency",
    "headerStyles",
    "containerStyles",
    "buttonStyles",
    "limitType",
    "isSelected",
    "productSetIdx"
  ],
  data(){
    return {
      selectedVariant: null,
      showWarning: false,
      variantOpts: [{}]
    }
  },
  mounted: function(){
    if(this.limitType === 1){
      if(this.product.variants && this.inCart.length > 0){
        this.selectedVariant = this.inCart[0].variantId;
      }
    }
    if(this.limitType === 2 || this.limitType === 3){
      if(this.product.variants && this.product.variants.length > 0){
        const prodId = this.product.id;
        const vm = this;
        this.variantOpts = this.product.variants.map((v) => {
          let q = 0;
          if(vm.inCart.length > 0 && vm.inCart.find(p => p.variantId == v.id)){
            q = vm.inCart.find(p => p.variantId == v.id).quantity;
          }
          return {
            productId: prodId,
            variantId: v.id,
            name: v.name,
            quantity: q,
            stock_quantity: v.stock_quantity,

          }
        })
      } else {
        this.variantOpts = [{
          productId: this.product.id,
          variantId: null,
          name: this.product.name,
          quantity: this.inCart.length > 0 ? this.inCart[0].quantity : 0,
        }]
      }
    }
  },
  computed: {
    primaryImage(){
      return this.product.primary_thumb_big ? this.product.primary_thumb_big : this.product.primary_image;
    },
    hasVariants(){
      return this.product.variants.length > 0;
    },
    selectedCount(){
      if(this.limitType === 1){
        if(this.selectedVariant || !this.hasVariants){
          return 1;
        } else {
          return 0;
        }
      } else if(this.limitType === 2 || this.limitType === 3){
        return this.variantOpts.map(v => v.quantity || 0).reduce((x, y) => x + y);
      }
      return 0;
    },
    localTotal(){
      if(!this.product.price){
        return 0.00;
      }
      if(this.variantOpts.length > 0){
        return (this.product.price * this.selectedCount).toFixed(2);
      }
      return 0.00
    },
    notEnoughInStock(){
      if((this.limitType === 2 || this.limitType === 3) && this.product.stock_management){
        if(this.variantOpts.some(function(v){ return v.quantity > v.stock_quantity;})){
          return true;
        }
      }
      return false;
    },
    deliveryTime(){
      const dTimes = {
        1: "orderDelivery3-5bd",
        2: "orderDelivery5-10bd",
        3: "orderDeliveryWithin2wks",
        4: "orderDeliveryWithin4wks",
        5: "orderDeliveryWithin6wks",
        6: "orderDeliveryWithin2mos",
        7: "orderDeliveryWithin4mos"
      }
      return dTimes[this.product.delivery_time]
    },
    dropdownBackgroundColor() {
      let backgroundColor = ''
      if(this.containerStyles['background-color'].length === 9) {
        backgroundColor = this.containerStyles['background-color'].slice(0, -2);
      }else{
        backgroundColor = this.containerStyles['background-color']
      }
      const color = this.containerStyles['color']
      return {backgroundColor : backgroundColor , color : color}
    },
    dropdownHighlight() {
      const backgroundColor = this.containerStyles['color']
      let color = ''
      if(this.containerStyles['background-color'].length === 9){
        color = this.containerStyles['background-color'].slice(0, -2);
      }else{
        color = this.containerStyles['background-color']
      }
      return {backgroundColor : backgroundColor , color : color}
    }
  },
  methods: {
    closeModal(){
      this.$emit('closeMoreInfoModal');
    },
    addToOrder(){
      if (this.notEnoughInStock || !this.selectedCount) {
        return;
      }
      if (this.limitType === 1) {
        let data = {
          productId: this.product.id,
          quantity: 1,
          variantId: null
        }
        if (this.hasVariants) {
          if (!this.selectedVariant) {
            this.showWarning = true;
            return;
          }
          data.variantId = this.selectedVariant;
        }
        this.$emit('addToOrder', [data], this.productSetIdx);
      } else if (this.limitType === 2 || this.limitType === 3) {
        // unlimited, emit variant values
        this.$emit('addToOrder', this.variantOpts, this.productSetIdx);
      }
    },
    updateOrder(){
      if (this.notEnoughInStock) {
        return
      }
      if (this.limitType === 1) {
        let data = {
          productId: this.product.id,
          quantity: 1,
          variantId: null
        }
        if (this.hasVariants) {
          if (!this.selectedVariant) {
            this.showWarning = true;
            return;
          }
          data.variantId = this.selectedVariant;
        }
        this.$emit('updateOrder', [data], this.productSetIdx);
      } else if (this.limitType === 2 || this.limitType === 3) {
        // unlimited
        this.$emit('updateOrder', this.variantOpts, this.productSetIdx);
      }
    },
    removeFromOrder(){
      let data = {productId: this.product.id}
      this.$emit('removeFromOrder', data, this.productSetIdx);
    },
    withBR(replace_str){
      return replace_str ? replace_str.replace(/(?:\r\n|\r|\n)/g, '<br />') : "";
    },
  }
}
</script>
<style>
.mySelect .vs__search,
.mySelect .vs__search::placeholder,
.mySelect .vs__dropdown-toggle,
.mySelect .vs__dropdown-option,
.mySelect .vs__selected,
.mySelect .vs__dropdown-menu {
  background-color: var(--dropdown-bg-color);
  color: var(--dropdown-color);
}

.mySelect .vs__dropdown-option--selected,
.mySelect .vs__dropdown-option--highlight {
  background-color: var(--dropdown-highlight-bg);
  color: var(--dropdown-highlight-color);
 }

.mySelect .vs__open-indicator,
.mySelect .vs__open-indicator--disabled {
  fill: var(--dropdown-highlight-bg);
}
</style>
