<template>
  <div>
    <div class="row animated-container">
      <div class="col-12 col-md-6">
        <img :src="imageUrl" class="w-100">
      </div>
      <div class="col-12 col-md-6 align-self-center">
        <h1 :style="headerStyles">{{header}}</h1>
        <div v-html="formattedText"></div>
        <div class="mt-3">{{signature}}</div>
        <div v-if="actionButtons" class="row mt-3">
          <div v-if="actionButtons.primary" class="col-md-6">
            <button class="btn btn-lg btn-block mt-2 button-hover" :style="primaryActionButtonStyles" v-on:click="openInNewTab(actionButtons.primary.url)">
              {{ actionButtons.primary.text }}
            </button>
          </div>
          <div v-if="actionButtons.secondary" class="col-md-6">
            <button class="btn btn-lg btn-block mt-2 button-hover" :style="secondaryActionButtonStyles" v-on:click="openInNewTab(actionButtons.secondary.url)">
              {{ actionButtons.secondary.text }}
            </button>
          </div>
        </div>
        <div v-if="!isMultiInvitation" class="mt-3">
          {{ $t('thankYouPssst') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomerThanksPage',
  props: {
    imageUrl: String,
    header: String,
    text: String,
    signature: String,
    headerStyles: Object,
    actionButtons: Object,
    primaryActionButtonStyles: Object,
    secondaryActionButtonStyles: Object,
    isMultiInvitation: Boolean
  },
  computed: {
    formattedText(){
      return this.text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
  },
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank');
    }
  }
}

</script>
