<template>
  <div class="row animated-container">
    <div class="col-12 col-md-6 mb-4">
      <img v-if="imageUrl" :src="imageUrl" class="w-100">
    </div>
    <div class="col-12 col-md-6 align-self-center">
      <h1 :style="headerStyles">{{header}}</h1>
      <div v-html="formattedText"></div>
      <div class="mt-md-3">{{signature}}</div><br>
      <div v-if="isMultiInvitation">{{ $t('introPagePssstMulti') }} {{expiryDate}}</div>
      <div v-else>{{ $t('introPagePssst') }} {{expiryDate}}</div>
      <div class="row">
        <div class="col-12 col-md-6 mt-2">
          <button class="btn btn-lg btn-block mt-2 button-hover"  :style="buttonStyles.primary" v-on:click="selectPage()">{{ $t('introPageButton') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomerIntroPage',
  props: ['expiryDate', 'imageUrl', 'header', 'text', 'signature', 'headerStyles', 'buttonStyles', 'isMultiInvitation'],
  computed: {
    formattedText(){
      return this.text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    },
  },
  methods:{
    selectPage(){
      this.$emit('toSelectPage');
    }
  }
}

</script>

